:root {
  --bg-image: url('https://images.unsplash.com/photo-1538370965046-79c0d6907d47?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80');
}

a {
  text-decoration: none;
  color: inherit;
}


.hero {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;

}
  /* teal */


.heroColor {
  /* color: #fff; */
  background-color: white;
  opacity: 0.9;

  /* background: var(--bg-image); */
  /* background-image: url('../img/backdrop.jpg'); */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.25' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), 
  linear-gradient( rgba(0, 128, 128, 128), rgb(0, 128, 128)); */
}

.eroColor{
  /* Set the SVG image as the background */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.25' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

  /* Ensure the background is transparent */
  background-color: transparent;

  /* Additional background properties */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}




.hero section {
  flex: 1;
}

.hero-left h1 {
  /* font-size: 2.5rem; */
  margin-bottom: 25px;

}

.hero-left p {
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 25px;
  color: #444444;
}

.hero-left p {
  /* content: ""; */
  width: 100%;
  height: 100%;
  background-color: #b9e7e7;
  /* position: absolute; */
  padding: 8px;
  top: 20px;
  left: -20px;
  z-index: -1;
}

.hero-left {
  padding-right: 40px;
}

.hero-left button {
  text-decoration: none;
  font-weight: 600;
  /* border-radius: 4px; */
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.3s all ease;

}

.hero-left button:hover {
  background-color: #ffffff;
  color: teal;
}

.right {
  padding-top: 50px;
}

.right img {
  width: 100%;
  max-width: 500px;
}

.middle{
  padding: 20px 0px;
}



.bg-blur {
  overflow: hidden;

}

.bg-blur::before {
  content: '';
  background-image: url("../img/mawuli.jpg");
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  filter: blur(50px);
  opacity: 0.6;
  z-index: -2;
  overflow-y: hidden!important;
}

.bg-blur::after {
  content: '';
  background: rgba(255, 255, 255, 0.666); 
  height: 100%;
  width: 100%;
  /* position: absolute; */
  z-index: -1; 
  /* Above the blurred image */
}



.bg-blur-2 {
  overflow: hidden;
}

.bg-blur-2::before {
  content: '';
  background-image: url("https://i.ibb.co/XF2gqZz/sika.jpg");
  background-size: contain;
  height: 100%;
  width: 100%;
  position: absolute;
  filter: blur(70px);
  opacity: 0.1;
  z-index: -10;
}

.bg-blur::after {
  content: '';
  background: rgba(255, 255, 255, 0.5); 
  height: 100%;
  width: 100%;
  /* position: absolute; */
  position: relative;
  z-index: -1;
} 




/* Scroll  */
.scroll-watcher{
  height: 3px;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: rgb(255, 234, 127);
  width: 100%;
  scale: 0 1;
  animation:scroll-watcher linear;
  animation-timeline: scroll();
}

@keyframes scroll-watcher {
  to { scale: 1 1; }
}

@media screen and (max-width: 1000px) {


  .hero {
    flex-direction: column;
    text-align: center;
  }

  .hero .right {
    order: -1;
    padding-bottom: 5rem;
  }

  .hero-left {
    padding-right: 0px !important;
  }
}


/* news card  */