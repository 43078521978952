::selection {
    background-color: #4EC6DE;
}

.tabbed {
    width: 700px;
    margin: 50px auto;
}

.tabbed>input {
    display: none;
}

.tabbed>label {
    display: block;
    float: left;
    padding: 12px 20px;
    margin-right: 5px;
    cursor: pointer;
    transition: background-color .3s;
}

.tabbed>label:hover,
.tabbed>input:checked+label {
    background: #4EC6DE;
}

.tabs {
    clear: both;
    perspective: 600px;
}

.MuiBox-root .tabs>div {
    width: 700px;
    position: absolute;
    border: 2px solid #4EC6DE;
    padding: 10px 30px 40px;
    line-height: 1.4em;
    opacity: 0;
    transform: rotateX(-20deg);
    transform-origin: top center;
    transition: opacity .3s, transform 1s;
    z-index: 0;
}

#tab-nav-1:checked~.tabs>div:nth-of-type(1),
#tab-nav-2:checked~.tabs>div:nth-of-type(2),
#tab-nav-3:checked~.tabs>div:nth-of-type(3),
#tab-nav-4:checked~.tabs>div:nth-of-type(4) {
    transform: rotateX(0);
    opacity: 1;
    z-index: 1;
}


.css-1q2h7u5.Mui-selected {
    color: rgb(25, 118, 210);
    background: #b9e7e7;
}

@media screen and (max-width: 700px) {
    .tabbed {
        width: 400px
    }

    .tabbed>label {
        display: none
    }

    .tabs>div {
        width: 400px;
        border: none;
        padding: 0;
        opacity: 1;
        position: relative;
        transform: none;
        margin-bottom: 60px;
    }

    .tabs>div h2 {
        border-bottom: 2px solid #4EC6DE;
        padding-bottom: .5em;
    }
}


.MuiBox-root {

    border-bottom: 2px solid #f8f9fa;
    /* padding: 10px 30px 40px; */
    transform: rotateX(-20deg);
    transform-origin: top center;
    transition: opacity .3s, transform 1s;

}

.MuiBox-root button {
    border: 2px solid #f8f9fa;
    margin-right: 10px;
    margin-bottom: 10px;
    background: white !important;
    color: inherit;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.MuiBox-root button:hover button::after {
    background-color: #FF4136;
    height: 300px;
}


.css-heg063-MuiTabs-flexContainer {
    background-color: #f8f9fa;
}

.css-19kzrtu {
    padding: 24px 0px 24px 0px !important;
}


.middle button {
    border: 2px solid #f8f9fa;
    margin-right: 10px;
    margin-bottom: 10px;
    background: white !important;
    color: #6c757d;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* style={{ background:'transparent',color:'black', border: 'solid', margin:'20px'}} */