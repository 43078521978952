.blog-card {
    display: flex;
    position: relative;
    height: 320px;
    transition: all 450ms ease-out 0s;
    overflow: hidden;
}

.blog-card .blog-img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    height: 60%;
    margin: auto;
    overflow: hidden;
}

.blog-card:hover {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.85);
}

.text-overlay h4 {
    font-size: 18px;
}

.text-overlay {
    position: relative;
    /* background: rgba(255, 255, 255, 0.95); */
    background: white;
    width: 100%;
    height: 50px;
    top: 84%;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.3);
    padding: 10px 12px;
    overflow: hidden;
    transition: all 450ms ease-in-out 0s;
    cursor: pointer;
}

.blog-card:hover .text-overlay {
    background: white;
    height: 80%;
    top: 30%;
    transition: all 450ms ease-in-out 0s;
}



.blog-card:hover {
    transition: all 350ms ease-in-out 0s;
}

.blog-card:hover p a {
    visibility: visible;
}


@media screen and (max-width: 600px) {
    .blog-card {
        /* height: 250px !important; */
        height: 300px !important;

    }

    /* 
    .blog-card {
        display: flex;
        position: relative;
        height: 300px; */

    /* .blog-card:hover .text-overlay {
        background: white;
        transition: all 450ms ease-in-out 0s;
    } */

    .blog-card .blog-img {
        height: 100%;
        margin: auto;
        overflow: hidden;
    }


    /* .text-overlay {
        top: 85% !important;
    } */
}



/* Style the accordion list */
.accordion-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Style the accordion items */
.accordion-item {
    /* border: 1px solid #ccc; */
    margin-bottom: 10px;
}

/* Style the accordion toggle */
.accordion-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
    cursor: pointer;
}

/* Style the accordion title */
.accordion-toggle h1 {
    margin: 0;
    /* font-size: 18px; */
}

/* Style the accordion icon */
.accordion-toggle span {
    font-size: 24px;
    transition: transform 0.3s;
}

/* Rotate the icon when the accordion is active */
.accordion-toggle.active span {
    transform: rotate(45deg);
}

/* Style the accordion content */
.accordion-content {
    padding: 15px 0 15px 0;
    /* max-height: 0; */
    overflow: hidden;
    transition: max-height 0.3s;
}

/* Set the max-height of the content when the accordion is active */
.accordion-toggle.active+.accordion-content {
    max-height: 200px;
}